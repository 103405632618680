import { SimpleDialog } from "@/common/SimpleDialog";
import { ListElementWithSwitch } from "@/common/ListElementWithSwitch";
import { useEffect, useState } from "react";
import { Alert, Divider } from "@mui/material";
import { CompanyPermissions } from "tb-utils";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { useQueryParam } from "@/customHooks/useQueryParam";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { useSigninCheck } from "reactfire";
import { appLogger } from "@/helper/appLogger";
import { usePermission } from "@/customHooks/usePermission";
import { useCompanyStore } from "@/stores/useCompanyStore";

const FILE_NAME = "C_ManageEmployeeRightsDialog.tsx";

type RightsType = { [key in CompanyPermissions]: boolean };

export function C_ManageEmployeePermissionsDialog() {
    const { myPerms } = usePermission();
    const { data: userData } = useSigninCheck();
    const { value: selectedUserId, remove: removeSelectedUserId } = useQueryParam("edit-user");
    const { updateCompanyMember } = useFirestoreWrite();

    const members = useCompanyPersistStore(state => state.members);
    const companyData = useCompanyStore(state => state.companyData);

    const companyModules = companyData.data?.modules;
    const selectedMember = members.find(m => m.user_id === selectedUserId);

    const [errorText, setErrorText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [rights, setRights] = useState<RightsType>({
        ADMIN: false,
        CONTAINER_NOTIFICATIONS: false,
        CREATE_WORK_TYPE: false,
        DRIVING_JOBS_VIEW_ALL: false,
        DRIVER_REPORTS_VIEW: false,
        DRIVER_REPORTS_APPROVE: false,
        CONTAINER_CONTRACTS_VIEW: false,
        CONTAINER_CONTRACTS_EDIT: false
    });

    useEffect(() => {
        const mr = selectedMember?.permissions;
        setRights({
            ADMIN: mr?.includes("ADMIN") || false,
            CONTAINER_NOTIFICATIONS: mr?.includes("CONTAINER_NOTIFICATIONS") || false,
            CREATE_WORK_TYPE: mr?.includes("CREATE_WORK_TYPE") || false,
            DRIVING_JOBS_VIEW_ALL: mr?.includes("DRIVING_JOBS_VIEW_ALL") || false,
            DRIVER_REPORTS_VIEW: mr?.includes("DRIVER_REPORTS_VIEW") || false,
            DRIVER_REPORTS_APPROVE: mr?.includes("DRIVER_REPORTS_APPROVE") || false,
            CONTAINER_CONTRACTS_VIEW: mr?.includes("CONTAINER_CONTRACTS_VIEW") || false,
            CONTAINER_CONTRACTS_EDIT: mr?.includes("CONTAINER_CONTRACTS_EDIT") || false
        });
    }, [selectedMember]);

    const isOwnUser = selectedUserId === userData?.user?.uid;
    const amIAdmin = myPerms?.includes("ADMIN");

    const toggleRight = (right: CompanyPermissions, giveRight?: boolean) => {
        /** User should not edit own rights */
        if (isOwnUser && right !== "CONTAINER_NOTIFICATIONS") return;

        setRights(current => ({
            ...current,
            [right]: giveRight ?? !current[right],
        }));
    };

    /** Create array including all user rights */
    const createNewRightsArray = () => {
        let resultArr: CompanyPermissions[] = [];
        Object.entries(rights).forEach(r => {
            if (r[1]) resultArr.push(r[0] as CompanyPermissions);
        });
        return resultArr.sort();
    };

    return <SimpleDialog
        title={`Rechte und Rollen von ${selectedMember?.displayName} verwalten`}
        name=""
        show={!!selectedUserId}
        handleCustomBackdropClick={removeSelectedUserId}
        fullscreenLoading={{ loading: isLoading }}
        actionButtons={[
            {
                text: "Abbrechen",
                closeDialogOnClick: true,
            },
            {
                text: "Speichern",
                disabled: (
                    isOwnUser &&
                    selectedMember?.permissions.includes("CONTAINER_NOTIFICATIONS") === rights.CONTAINER_NOTIFICATIONS
                ),
                onClick: () => {
                    setIsLoading(true);

                    updateCompanyMember(selectedUserId || "", {
                        permissions: createNewRightsArray(),
                    })
                        .then(() => {
                            removeSelectedUserId();
                            window.location.reload(); // Reload page to show new permissions when opening the dialog again (It's a not pretty solution, but it works)
                        })
                        .catch(err => {
                            appLogger.error(FILE_NAME, "Error updating company member permissions: " + err);
                            setErrorText("Fehler beim Speichern.");
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                }
            }
        ]}
    >
        {isOwnUser &&
            <Alert color="error" icon={false}>
                Du kannst deine eigenen Rechte nicht ändern.
            </Alert>
        }
        {!amIAdmin &&
            <Alert color="error" icon={false}>
                Du musst Administrator sein, um Rechte bearbeiten zu können.
            </Alert>
        }

        <ListElementWithSwitch
            primary="Administrator"
            secondary="Der Mitarbeiter hat keine Einschränkungen."
            sidePadding="small"
            checked={rights.ADMIN}
            onChange={() => toggleRight("ADMIN")}
        />

        {companyModules?.includes("CONTAINER_MODULE") && <>
            <Divider/>
            <ListElementWithSwitch
                primary="Aktivitätsmeldungen"
                secondary="Der Mitarbeiter erhält Benachrichtigungen über Aktivitäten in der Firma."
                sidePadding="small"
                checked={rights.CONTAINER_NOTIFICATIONS}
                onChange={() => toggleRight("CONTAINER_NOTIFICATIONS")}
            />
        </>}

        <Divider/>

        <ListElementWithSwitch
            primary="Alle Fahrten einsehen"
            secondary="Der Mitarbeiter kann für jeden Tag die festgelegten Fahrten einsehen."
            sidePadding="small"
            checked={rights.DRIVING_JOBS_VIEW_ALL}
            onChange={() => toggleRight("DRIVING_JOBS_VIEW_ALL")}
            disabled={rights.ADMIN}
        />

        <Divider/>

        <ListElementWithSwitch
            primary="Berichte einsehen"
            secondary="Der Mitarbeiter kann von allen die Berichte einsehen."
            sidePadding="small"
            checked={rights.DRIVER_REPORTS_VIEW}
            onChange={() => {
                toggleRight("DRIVER_REPORTS_VIEW");
                if (rights.DRIVER_REPORTS_APPROVE) {
                    toggleRight("DRIVER_REPORTS_APPROVE", false);
                }
            }}
            disabled={rights.ADMIN}
        />
        <ListElementWithSwitch
            primary="Berichte genehmigen"
            secondary="Der Mitarbeiter kann Berichte genehmigen."
            sidePadding="small"
            checked={rights.DRIVER_REPORTS_APPROVE}
            onChange={() => {
                toggleRight("DRIVER_REPORTS_APPROVE")
                if (!rights.DRIVER_REPORTS_VIEW) {
                    toggleRight("DRIVER_REPORTS_VIEW", true);
                }
            }}
            disabled={rights.ADMIN}
        />

        <Divider/>

        <ListElementWithSwitch
            primary="Aufträge einsehen"
            secondary="Der Mitarbeiter kann alle Aufträge einsehen."
            sidePadding="small"
            checked={rights.CONTAINER_CONTRACTS_VIEW}
            onChange={() => {
                toggleRight("CONTAINER_CONTRACTS_VIEW")
                if (rights.CONTAINER_CONTRACTS_EDIT) {
                    toggleRight("CONTAINER_CONTRACTS_EDIT", false);
                }
            }}
            disabled={rights.ADMIN}
        />
        <ListElementWithSwitch
            primary="Aufträge bearbeiten"
            secondary="Der Mitarbeiter kann Aufträge erstellen, bearbeiten und löschen."
            sidePadding="small"
            checked={rights.CONTAINER_CONTRACTS_EDIT}
            onChange={() => {
                toggleRight("CONTAINER_CONTRACTS_EDIT")
                if (!rights.CONTAINER_CONTRACTS_VIEW) {
                    toggleRight("CONTAINER_CONTRACTS_VIEW", true);
                }
            }}
            disabled={rights.ADMIN}
        />

        {errorText &&
            <Alert color="error" icon={false}>
                {errorText}
            </Alert>
        }
    </SimpleDialog>
}
